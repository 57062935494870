var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "medicine" },
    [
      _c("b-table", {
        staticClass: "mt-5",
        attrs: {
          items: _vm.items,
          fields: _vm.fields,
          "thead-tr-class": "headerClass",
          busy: _vm.isLoadingFilters
        },
        scopedSlots: _vm._u([
          {
            key: "cell(filter_name)",
            fn: function(data) {
              return [
                _c("b", [
                  _vm._v(_vm._s(_vm.$t("admin.medicines." + data.value)))
                ])
              ]
            }
          },
          {
            key: "cell(input)",
            fn: function(data) {
              return [
                _c("b-form-input", {
                  staticClass: "w-75",
                  attrs: { placeholder: "+ " + _vm.placeholder },
                  model: {
                    value: _vm.form[data.value],
                    callback: function($$v) {
                      _vm.$set(_vm.form, data.value, $$v)
                    },
                    expression: "form[data.value]"
                  }
                })
              ]
            }
          },
          {
            key: "cell(options)",
            fn: function(row) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-2",
                    attrs: { size: "sm" },
                    on: { click: row.toggleDetails }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(row.detailsShowing ? _vm.hide : _vm.show) +
                        " "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "b-card",
                  {},
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "text-sm-right text-secondary",
                            attrs: { sm: "4" }
                          },
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("admin.medicines.options")) + ":"
                              )
                            ])
                          ]
                        ),
                        _c("b-col", [
                          _c(
                            "ol",
                            _vm._l(row.item.options, function(option) {
                              return _c("li", { key: option }, [
                                _vm._v(" " + _vm._s(option) + " ")
                              ])
                            }),
                            0
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "table-busy",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-primary my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _c("strong", [_vm._v("Loading...")])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "b-row",
        { staticClass: "justify-content-end" },
        [
          _vm.configRol.administrador.pestana.medicines.acciones.crearFiltro
            ? _c(
                "b-button",
                {
                  staticClass: "btn btn-blue-dark mx-3",
                  attrs: { disabled: _vm.isLoadingDone },
                  on: { click: _vm.handleSave }
                },
                [
                  _vm.isLoadingDone
                    ? _c("span", [_vm._v("Loading...")])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("admin.medicines.done")))
                      ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "mt-5", attrs: { role: "group" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-4 align-items-center text-align-end" },
            [
              _c("b-col", { attrs: { cols: "5" } }),
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "md" } },
                    [
                      _c("b-form-input", {
                        staticClass: "custom-input",
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: this.$i18n.t(
                            "admin.medicines.placeholderSearch"
                          )
                        },
                        model: {
                          value: _vm.filterUsers,
                          callback: function($$v) {
                            _vm.filterUsers = $$v
                          },
                          expression: "filterUsers"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "input__search__icon",
                    attrs: {
                      icon: "search",
                      "aria-hidden": "true",
                      "flip-h": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _vm.validationDropdown
                    ? _c(
                        "b-dropdown",
                        {
                          ref: "grouperForm",
                          staticClass: "m-2",
                          attrs: {
                            id: "dropdown-form",
                            "menu-class": "form-user",
                            right: "",
                            variant: "link",
                            "toggle-class": "text-decoration-none",
                            "no-caret": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function() {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "button btn-blue-dark ml-0",
                                        attrs: { variant: "outline-primary" }
                                      },
                                      [
                                        _vm.selectedGrouper.length
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("admin.medicines.edit")
                                                ) + " +"
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "admin.medicines.create"
                                                  )
                                                ) + " +"
                                              )
                                            ])
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1217846092
                          )
                        },
                        [
                          _c(
                            "b-dropdown-text",
                            { staticClass: "my-3 text-header-user" },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "person-plus",
                                  "aria-hidden": "true"
                                }
                              }),
                              _vm.selectedGrouper.length
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.medicines.edit"))
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.medicines.create"))
                                    )
                                  ])
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-form",
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.medicines.name")) + " * "
                                )
                              ]),
                              _c("b-form-input", {
                                staticClass: "form-input-style mt-0",
                                attrs: {
                                  size: "sm",
                                  placeholder: this.$i18n.t(
                                    "admin.medicines.name"
                                  ),
                                  state: _vm.validationName
                                },
                                model: {
                                  value: _vm.inputGrouper.nombre,
                                  callback: function($$v) {
                                    _vm.$set(_vm.inputGrouper, "nombre", $$v)
                                  },
                                  expression: "inputGrouper.nombre"
                                }
                              }),
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.medicines.cycles")) +
                                    " *"
                                )
                              ]),
                              _c("b-form-select", {
                                staticClass: "select mt-0",
                                attrs: { options: _vm.cycles, size: "sm" },
                                model: {
                                  value: _vm.inputGrouper.ciclo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.inputGrouper, "ciclo", $$v)
                                  },
                                  expression: "inputGrouper.ciclo"
                                }
                              }),
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.medicines.dosage")) +
                                    " *"
                                )
                              ]),
                              _c("b-form-select", {
                                staticClass: "mt-0 select",
                                attrs: { options: _vm.dosage, size: "sm" },
                                model: {
                                  value: _vm.inputGrouper.dosis,
                                  callback: function($$v) {
                                    _vm.$set(_vm.inputGrouper, "dosis", $$v)
                                  },
                                  expression: "inputGrouper.dosis"
                                }
                              }),
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.medicines.medicine"))
                                )
                              ]),
                              _c("b-form-select", {
                                staticClass: "mt-0 select",
                                attrs: { options: _vm.medicine, size: "sm" },
                                model: {
                                  value: _vm.inputGrouper.medicamento,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.inputGrouper,
                                      "medicamento",
                                      $$v
                                    )
                                  },
                                  expression: "inputGrouper.medicamento"
                                }
                              }),
                              _c("label", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.medicines.intelisis")) +
                                    " (" +
                                    _vm._s(
                                      _vm.$t("admin.medicines.validation")
                                    ) +
                                    ") *"
                                )
                              ]),
                              _c("b-form-input", {
                                staticClass: "form-input-style mt-0",
                                attrs: {
                                  size: "sm",
                                  placeholder: this.$i18n.t(
                                    "admin.medicines.intelisis"
                                  ),
                                  state: _vm.validationCode
                                },
                                model: {
                                  value: _vm.inputGrouper.codigosInt,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.inputGrouper,
                                      "codigosInt",
                                      $$v
                                    )
                                  },
                                  expression: "inputGrouper.codigosInt"
                                }
                              }),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "justify-content-end buttons-dropdown"
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "btn btn-blue-dark-outline ml-0",
                                      on: { click: _vm.cancel }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.users.cancel")) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.selectedGrouper.length
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "btn btn-blue-dark ml-0",
                                          attrs: {
                                            disabled:
                                              _vm.isLoadingFilters ||
                                              !_vm.isValidGrouper
                                          },
                                          on: { click: _vm.handleUpdateGrouper }
                                        },
                                        [
                                          !_vm.isLoadingSaveGrouper
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("admin.users.save")
                                                  )
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.medicines.loading"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                          _c("b-icon", {
                                            staticClass: "icon",
                                            attrs: {
                                              icon: "arrow-right",
                                              "aria-hidden": "true"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-button",
                                        {
                                          staticClass: "btn btn-blue-dark ml-0",
                                          attrs: {
                                            disabled:
                                              _vm.isLoadingFilters ||
                                              !_vm.isValidGrouper
                                          },
                                          on: { click: _vm.handleSaveGrouper }
                                        },
                                        [
                                          !_vm.isLoadingSaveGrouper
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "admin.users.create"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "admin.medicines.loading"
                                                    )
                                                  ) + " "
                                                )
                                              ]),
                                          _c("b-icon", {
                                            staticClass: "icon",
                                            attrs: {
                                              icon: "arrow-right",
                                              "aria-hidden": "true"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.configRol.administrador.pestana.medicines.acciones.listar
            ? [
                _c("b-table", {
                  staticClass: "tableFirst",
                  attrs: {
                    items: _vm.tableGroupers,
                    fields: _vm.fieldsGroupers,
                    filter: _vm.filterUsers,
                    "filter-included-fields": _vm.filterOn,
                    stacked: "md",
                    "show-empty": "",
                    hover: "",
                    "table-variant": "light",
                    "sort-icon-left": "",
                    "select-mode": "single",
                    selectable: "",
                    borderless: "",
                    "tbody-tr-class": "table-users",
                    busy: _vm.isLoadingGroupers
                  },
                  on: { "row-selected": _vm.onRowselectedGrouper },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(nombre)",
                        fn: function(row) {
                          return [_c("b", [_vm._v(_vm._s(row.value))])]
                        }
                      },
                      {
                        key: "cell(status)",
                        fn: function(row) {
                          return [
                            _vm.configRol.administrador.pestana.medicines
                              .acciones.eliminarAgrupador
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    class: {
                                      "bg-success": row.value,
                                      "bg-danger": !row.value
                                    },
                                    attrs: {
                                      size: "sm",
                                      disabled: _vm.isLoadingChangeStatus
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.changeStatus(
                                          row.item,
                                          !row.value
                                        )
                                      }
                                    }
                                  },
                                  [
                                    row.value
                                      ? _c("span", [_vm._v(" Activo ")])
                                      : _c("span", [_vm._v(" Inactivo ")]),
                                    _vm.isLoadingChangeStatus
                                      ? _c("b-spinner", {
                                          staticClass: "align-middle",
                                          attrs: { small: "" }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-primary my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2730824414
                  )
                })
              ]
            : [
                _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("permissions.msgPermissionsMedicine")))
                  ])
                ])
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }