<template>
  <div class="medicine">
    <b-table
      :items="items"
      :fields="fields"
      class="mt-5"
      thead-tr-class="headerClass"
      :busy="isLoadingFilters"
    >
      <template #cell(filter_name)="data">
        <b>{{ $t(`admin.medicines.${data.value}`) }}</b>
      </template>

      <template #cell(input)="data">
        <b-form-input
          v-model="form[data.value]"
          :placeholder="`+ ${placeholder}`"
          class="w-75"
        ></b-form-input>
      </template>
      <template #cell(options)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? hide : show }}
        </b-button>
      </template>
      <template #row-details="row">
        <b-card class="">
          <b-row class="mb-2">
            <b-col sm="4" class="text-sm-right text-secondary"
              ><b>{{ $t("admin.medicines.options") }}:</b></b-col
            >
            <b-col>
              <ol>
                <li v-for="option in row.item.options" :key="option">
                  {{ option }}
                </li>
              </ol>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row class="justify-content-end">
      <b-button
        class="btn btn-blue-dark mx-3"
        @click="handleSave"
        :disabled="isLoadingDone"
        v-if="configRol.administrador.pestana.medicines.acciones.crearFiltro"
      >
        <span v-if="isLoadingDone">Loading...</span>
        <span v-else>{{ $t("admin.medicines.done") }}</span>
      </b-button>
    </b-row>
    <hr />
    <!-- SECTION GROUPERS -->

    <div role="group" class="mt-5">
      <b-row class="mb-4 align-items-center text-align-end">
        <b-col cols="5"></b-col>
        <b-col>
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filterUsers"
              type="search"
              class="custom-input"
              :placeholder="this.$i18n.t('admin.medicines.placeholderSearch')"
            >
            </b-form-input>
          </b-input-group>
          <b-icon
            icon="search"
            aria-hidden="true"
            flip-h
            class="input__search__icon"
          ></b-icon>
        </b-col>
        <b-col>
          <b-dropdown
            id="dropdown-form"
            menu-class="form-user"
            right
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            ref="grouperForm"
            class="m-2"
             v-if="validationDropdown"
          >
            <template #button-content>
              <b-button
                variant="outline-primary"
                class="button btn-blue-dark ml-0"
              >
                <span v-if="selectedGrouper.length"
                  >{{ $t("admin.medicines.edit") }} +</span
                >
                <span v-else>{{ $t("admin.medicines.create") }} +</span>
              </b-button>
            </template>
            <b-dropdown-text class="my-3 text-header-user">
              <b-icon icon="person-plus" aria-hidden="true"></b-icon>
              <span v-if="selectedGrouper.length">
                {{ $t("admin.medicines.edit") }}</span
              >
              <span v-else>{{ $t("admin.medicines.create") }}</span>
            </b-dropdown-text>

            <!-- FORM -->
            <b-dropdown-form>
              <label>{{ $t("admin.medicines.name") }} * </label>
              <b-form-input
                class="form-input-style mt-0"
                size="sm"
                :placeholder="this.$i18n.t('admin.medicines.name')"
                :state="validationName"
                v-model="inputGrouper.nombre"
              ></b-form-input>
              <label>{{ $t("admin.medicines.cycles") }} *</label>
              <b-form-select
                v-model="inputGrouper.ciclo"
                :options="cycles"
                size="sm"
                class="select mt-0"
              >
              </b-form-select>

              <label>{{ $t("admin.medicines.dosage") }} *</label>
              <b-form-select
                v-model="inputGrouper.dosis"
                :options="dosage"
                size="sm"
                class="mt-0 select"
              >
              </b-form-select>

              <label>{{ $t("admin.medicines.medicine") }}</label>
              <b-form-select
                v-model="inputGrouper.medicamento"
                :options="medicine"
                size="sm"
                class="mt-0 select"
              >
              </b-form-select>

              <label
                >{{ $t("admin.medicines.intelisis") }} ({{
                  $t("admin.medicines.validation")
                }}) *</label
              >

              <b-form-input
                class="form-input-style mt-0"
                size="sm"
                :placeholder="this.$i18n.t('admin.medicines.intelisis')"
                :state="validationCode"
                v-model="inputGrouper.codigosInt"
              ></b-form-input>

              <b-col class="justify-content-end buttons-dropdown">
                <b-button
                  class="btn btn-blue-dark-outline ml-0"
                  @click="cancel"
                >
                  {{ $t("admin.users.cancel") }}
                </b-button>
                <b-button
                  class="btn btn-blue-dark ml-0"
                  :disabled="isLoadingFilters || !isValidGrouper"
                  @click="handleUpdateGrouper"
                  v-if="selectedGrouper.length"
                >
                  <span v-if="!isLoadingSaveGrouper">{{
                    $t("admin.users.save")
                  }}</span>
                  <span v-else> {{ $t("admin.medicines.loading") }} </span>
                  <b-icon
                    icon="arrow-right"
                    class="icon"
                    aria-hidden="true"
                  ></b-icon>
                </b-button>
                <b-button
                  class="btn btn-blue-dark ml-0"
                  :disabled="isLoadingFilters || !isValidGrouper"
                  @click="handleSaveGrouper"
                  v-else
                >
                  <span v-if="!isLoadingSaveGrouper">
                    {{ $t("admin.users.create") }}
                  </span>
                  <span v-else>{{ $t("admin.medicines.loading") }} </span>

                  <b-icon
                    icon="arrow-right"
                    class="icon"
                    aria-hidden="true"
                  ></b-icon>
                </b-button>
              </b-col>
            </b-dropdown-form>
          </b-dropdown>
        </b-col>
      </b-row>
      <!-- TABLE STATUS ACTIVE-->
      <template v-if="configRol.administrador.pestana.medicines.acciones.listar">
        <b-table
        :items="tableGroupers"
        :fields="fieldsGroupers"
        :filter="filterUsers"
        :filter-included-fields="filterOn"
        stacked="md"
        show-empty
        hover
        table-variant="light"
        class="tableFirst"
        sort-icon-left
        select-mode="single"
        selectable
        borderless
        tbody-tr-class="table-users"
        @row-selected="onRowselectedGrouper"
        :busy="isLoadingGroupers"
      >
        <template #cell(nombre)="row">
          <b>{{ row.value }}</b>
        </template>
        <template #cell(status)="row">
          <b-button
            size="sm"
            @click="changeStatus(row.item, !row.value)"
            class="mr-2"
            :class="{ 'bg-success': row.value, 'bg-danger': !row.value }"
            :disabled="isLoadingChangeStatus"
            v-if="configRol.administrador.pestana.medicines.acciones.eliminarAgrupador"
          >
            <span v-if="row.value"> Activo </span>
            <span v-else> Inactivo </span>
            <b-spinner
              v-if="isLoadingChangeStatus"
              class="align-middle"
              small
            ></b-spinner>
          </b-button>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      </template>
      <template v-else>
        <b-alert show dismissible>
          <p>{{ $t("permissions.msgPermissionsMedicine") }}</p>
        </b-alert>
      </template>
      
    </div>
  </div>
</template>

<script>
import { API_ROOT } from "../../Constants/Api";
import { AlertForm, ICON } from "../../helpers/Alert";
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: "MedicineAdmin",
  components: {},

  data: () => ({
    items: [],
    form: {
      dosis: null,
      medicamento: null,
      ciclo: null,
    },
    inputGrouper: {
      nombre: "",
      ciclo: "",
      dosis: "",
      medicamento: "",
      codigosInt: "",
    },
    codeIntelisis: "",
    liHtml: "",
    cycles: [],
    dosage: [],
    medicine: [],
    isLoadingFilters: false,
    isLoadingDone: false,
    isLoadingGroupers: false,
    isLoadingSaveGrouper: false,
    isLoadingChangeStatus: false,
    tableGroupers: [],
    name: "",
    email: "",
    filterUsers: null,
    filterOn: [],
    selectedRol: null,
    selectedStatus: null,
    inputName: "",
    inputEmail: "",
    selectedGrouper: [],
    optionsRol: [],
    optionsStatus: [
      { text: "Activo", value: true },
      { text: "Inactivo", value: false },
    ],

    isEdit: false,
    configRol: {}
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.medicines.permiso === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.History")}` , this.$i18n.t("admin.medicines.title"), ICON.WARNING, "center", null);
    } 
  },
  async mounted() {
    await this.updateTableFilters();
    await this.updateTableGroupers();
  },
  computed: {
     validationDropdown() {
        if(this.configRol.administrador.pestana.medicines.acciones.crearAgrupador && this.selectedGrouper.length === 0 ) {
          return true;
        } else if(this.configRol.administrador.pestana.medicines.acciones.editarAgrupador && this.selectedGrouper.length) {
          return true;
        }
        return false;
      },
    placeholder() {
      return this.$i18n.t("admin.medicines.placeholder");
    },
    hide() {
      return this.$i18n.t("admin.medicines.hide");
    },
    show() {
      return this.$i18n.t("admin.medicines.show");
    },
    fields() {
      return [
        {
          key: "filter_name",
          label: "",
        },
        {
          key: "input",
          label: this.$i18n.t("admin.medicines.newOption"),
        },
        {
          key: "options",
          label: this.$i18n.t("admin.medicines.addedOptions"),
        },
      ];
    },
    fieldsGroupers() {
      return [
        {
          key: "nombre",
          label: this.$i18n.t("admin.medicines.name"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "ciclo",
          label: this.$i18n.t("admin.medicines.cycles"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "dosis",
          label: this.$i18n.t("admin.medicines.dosage"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "codigo_intelisis",
          label: this.$i18n.t("admin.medicines.intelisis"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "status",
          label: this.$i18n.t("admin.medicines.status"),
          sortable: true,
          sortDirection: "desc",
        },
      ];
    },
    validationName() {
      return this.validationInputName();
    },
    validationCode() {
      return this.validationInputCodeInt();
    },

    isValidGrouper() {
      if (this.validationInputName() && this.validationInputCodeInt()) {
        if (this.inputGrouper.ciclo && this.inputGrouper.dosis) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    async handleSaveGrouper() {
      try {
        this.isLoadingSaveGrouper = true;
        await ApiRequest.post(`${API_ROOT}/agrupadorMedicamentos`,
          this.inputGrouper
        );
        this.inputGrouper = {
          nombre: "",
          ciclo: "",
          dosis: "",
          medicamento: "",
          codigosInt: "",
        };
        this.codeIntelisis = "";
        this.$refs.grouperForm.hide(true);
        AlertForm(this.$i18n.t("admin.medicines.success"), "",
          ICON.SUCCESS, "center");
        await this.updateTableGroupers();
      } catch (err) {
        if(err.status == 500 && err.data.msgError === "E11000" ){
          return  AlertForm(`Error Code: ${err.status}`, this.$i18n.t("admin.medicines.msgDuplicate"), ICON.ERROR, "center");
        }
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoadingSaveGrouper = false;
      }
    },

    async handleUpdateGrouper() {
      try {
        this.isLoadingSaveGrouper = true;
        await ApiRequest.put(`${API_ROOT}/agrupadorMedicamentos`, {
          ...this.inputGrouper,
          id: this.selectedGrouper[0]._id,
        });

        this.$refs.grouperForm.hide(true);
        AlertForm(this.$i18n.t("admin.medicines.update"), "",
          ICON.SUCCESS, "center");
        await this.updateTableGroupers();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoadingSaveGrouper = false;
      }
    },

    async changeStatus(data, status) {
      try {
        this.isLoadingChangeStatus = true;
        await ApiRequest.put(`${API_ROOT}/agrupadorMedicamentos/estado`, {
          id: data._id,
          estado: status ? 1 : 0,
        });
        await this.updateTableGroupers();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoadingChangeStatus = false;
      }
    },

    listOptions(options) {
      this.liHtml = options.map((el) => `<li>${el}</li>`).join("");
    },
    validationInputName() {
      return this.inputGrouper.nombre.length > 3;
    },
    validationInputCodeInt() {
      return this.inputGrouper.codigosInt.length > 3;
    },

    async getCycle() {
      try {
        const data = await ApiRequest.get(
          `${API_ROOT}/filtrosMedicamentos/carga_combos/ciclo`
        );
        this.cycles = data.records;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async getDosage() {
      try {
        const data = await ApiRequest.get(
          `${API_ROOT}/filtrosMedicamentos/carga_combos/dosis`
        );
        this.dosage = data.records;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async getMedicine() {
      try {
        const data = await ApiRequest.get(
          `${API_ROOT}/filtrosMedicamentos/carga_combos/medicamento`
        );
        this.medicine = data.records;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    updateOptions() {
      return [
        {
          input: "ciclo",
          filter_name: "cycles",
          options: this.cycles,
        },
        {
          input: "dosis",
          filter_name: "dosage",
          options: this.dosage,
        },
        {
          input: "medicamento",
          filter_name: "medicine",
          options: this.medicine,
        },
      ];
    },

    async updateTableFilters() {
      this.isLoadingFilters = true;
      await this.getCycle();
      await this.getDosage();
      await this.getMedicine();
      this.items = this.updateOptions();
      this.isLoadingFilters = false;
    },

    async getGrupersList() {
      let activeGroupers = [],
        inactiveGroupers = [];
      try {
        const data = await ApiRequest.post(`${API_ROOT}/agrupadorMedicamentos/estado`,
          { estado: 1 }
        );
        activeGroupers = data.lista.map((el) => {
          return { ...el, status: true };
        });
      } catch (err) {
         if(err.status !== "Data Failure") {
           AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
         }
      }
      try {
        const inactive = await ApiRequest.post(
          `${API_ROOT}/agrupadorMedicamentos/estado`,
          { estado: 0 }
        );
        inactiveGroupers = inactive.lista.map((el) => {
          return { ...el, status: false };
        });
      } catch (err) {
        if(err.status !== "Data Failure") {
           AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
         }
      }
      this.tableGroupers = [...activeGroupers, ...inactiveGroupers];
    },

    async updateTableGroupers() {
      this.isLoadingGroupers = true;
      await this.getGrupersList();
      this.isLoadingGroupers = false;
    },
    async handleSave() {
      let isValid = true;
      let count = 0;
      Object.entries(this.form).forEach((el) => {
        if (!el[1]) {
          isValid = false;
          this.form[el[0]] = "";
          count += 1;
        }
      });
      if (!isValid && count === 3)
        return AlertForm("Tienes campos vacios!", "", ICON.WARNING, "center");
      try {
        this.isLoadingDone = true;
        const data = await ApiRequest.post(`${API_ROOT}/filtrosMedicamentos`,
          this.form
        );
        AlertForm("success", data.msg, ICON.SUCCESS);
        await this.updateTableFilters();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoadingDone = false;
      }
    },
    onRowselectedGrouper(items) {
      this.selectedGrouper = items;
      if (items.length) {
        const [info] = items;

        this.inputGrouper = {
          nombre: info.nombre,
          ciclo: info.ciclo,
          dosis: info.dosis,
          medicamento: info.medicamento,
          codigosInt: info.codigo_intelisis,
        };
        this.codeIntelisis = info.codigo_intelisis.join(", ");
      } else {
        this.inputGrouper = {
          nombre: "",
          ciclo: "",
          dosis: "",
          medicamento: "",
          codigosInt: "",
        };
        this.codeIntelisis = "";
      }
    },
    cancel() {
      this.$refs.grouperForm.hide(true);
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";

.medicine {
  min-height: 100vh;
}
.medicine /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

/** FORM USER */
.form-user {
  font-weight: bold;
}
#input-name.form-control,
#input-email.form-control {
  color: #333232;
  background: #e9e8e8c9;
  border: none;
}
/**menu dropdown */
#dropdown-form /deep/ .form-user {
  width: 400px;
}
#dropdown-form /deep/ .b-dropdown-text {
  color: #707070;
  font-weight: bolder;
}
#dropdown-form input.form-input-style,
#dropdown-form /deep/ .select {
  font-weight: bold;
  border: none;
  border-radius: 0%;
  border-bottom: 1px solid #7070708a;
  margin: 15px 0;
}
#dropdown-form /deep/ .select {
  color: #707070c4;
}
#dropdown-form input.form-input-style::placeholder {
  color: #7070709d;
}
#dropdown-form input.form-input-style:focus {
  outline: none;
}
/**BUTTON STYLES */
.text-align-end {
  text-align: end;
}
#dropdown-form .buttons-dropdown {
  display: flex;
}
#dropdown-form .btn-blue-dark,
#dropdown-form .btn-blue-dark-outline {
  padding: 5px 10px;
  margin: 5px;
}
/**Table styles */
.tableFirst /deep/ .table-users {
  border-bottom: 1px solid rgb(160, 158, 158);
}
</style>
